import ReactDOM from 'react-dom';
import React, { useState } from 'react';

const Pin = () => {
    const [error,setError] = useState(false)

    function onChange(val) {
        setError(false)
        var input = val.replace(/[^\d]/g, "");
        document.getElementById("pinField").value = input;
        input.length === 4 && checkPin(input)
    }

    function checkPin(val) {
        if (val === process.env.REACT_APP_PIN) {
            localStorage.setItem("isPermission", true)
            window.location.reload()
        }
        else { 
            localStorage.setItem("isPermission", false) 
            setError(true)
        }
    }
    
    console.log(process.env.REACT_APP_PIN)
    return (
        <div class="container index-body">
            <div class="col-12 all-center align-center">
                <h2 class="font-italic align-center color-primary mb-3">enter pin</h2>
                <form>
                    <input pattern="[0-9]*" type="text" inputmode="numeric" maxLength="4" id="pinField" class="pinField" onChange={(e) => onChange(e.target.value)} autoFocus style={{color:`${error?"red":"black"}`}}/>
                </form>
                <p class="align-center mt-3 mb-1 color-secondary font-semibold">Don't have pin? Contact <a href="mailto:iam@erthp.com" class="color-secondary underline">iam@erthp.com</a></p>

                <a href="/"><p class="align-center mt-3 mb-1 color-primary font-semibold underline">back to home</p></a>
            </div>
        </div>
    )
}
export default Pin