import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PlVerME extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">VerME</h2>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">"VerME" A platform for checking identity for online buyer.<br/>
            They can checking for buyer's history from website database before buying some stuff.<br/>
            Conversely, online seller can create their identity card too.<br/>
            Visit website: <a href="https://verme.me/" target="_blank" rel="noopener noreferrer">https://verme.me/</a></p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FVerME%2Fverme-1.png?alt=media&token=0443ffc1-3994-4fbe-b50f-6d3af577199a" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Tutorial for VerME.<br/>
            User can simply create seller profile from the website.<br/>
            Then, user will received e-card for identity confirmation</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FVerME%2Fverme-2.png?alt=media&token=56218b2d-7bfc-421a-a379-5fb8537ed43a" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Seller checking for online buyer.<br/>
            User can check seller's identity from the website.<br/>
            They will found all provided information of seller.
            </p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FVerME%2Fverme-3.png?alt=media&token=fd158e5c-3b18-4555-81e5-bba371e5edb4" class="portfolio-image"/>
            </div>
        </div>
        <a href="/private-portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PlVerME;