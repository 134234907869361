import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import '../index.scss';

class Portfolio extends Component {
    render() {
      return (     
            <div class="container index-body">
                <div class="col-12 all-center align-center">
          <h2 class="font-italic align-center color-primary">welcome to erthp.portfolio</h2>
          <h4 class="font-italic align-center color-primary"><a href="/portfolio" class="color-secondary underline">click here to view</a></h4>
          <p class="align-center mt-3 mb-1 color-secondary font-semibold">If you have special invitation code, please <a href="/private-portfolio" class="color-secondary underline">click here</a>.</p>
          <a href="/"><p class="align-center mt-3 mb-1 color-primary font-semibold">back to home</p></a>
          </div>
      </div>
      )
    }
}
  
  export default Portfolio;