import React from 'react';
import { Router, Route } from 'react-router';
import DesignTeamRandom from './components/design-team-random';
import App from './components/App';
import About from './components/about';
import LandingPortfolio from './components/landing-portfolio';
import Portfolio from './components/portfolio';
import PrivatePortfolio from './components/private-portfolio';
import PvBYD from './components/private-portfolio/byd';
import PvTaladThai from './components/private-portfolio/taladthai';
import PvAmwayTW from './components/private-portfolio/amway-tw';
import PvBigC from './components/private-portfolio/bigc';
import PvVbApp from './components/private-portfolio/vb-app';
import PvVaPlatform from './components/private-portfolio/va-platform';
import PvSansiri from './components/private-portfolio/sansiri-joy-of-huahin';
import PvPraram9 from './components/private-portfolio/praram9';
import PvScasset from './components/private-portfolio/scasset';
import PvDribbble from './components/private-portfolio/dribbble';
import PvVerME from './components/private-portfolio/verme';
import PvKafaak from './components/private-portfolio/kafaak';
import PvMandarin from './components/private-portfolio/mandarin';

import PlBYD from './components/portfolio/byd';
import PlTaladThai from './components/portfolio/taladthai';
import PlAmwayTW from './components/portfolio/amway-tw';
import PlBigC from './components/portfolio/bigc';
import PlVbApp from './components/portfolio/vb-app';
import PlSansiri from './components/portfolio/sansiri-joy-of-huahin';
import PlPraram9 from './components/portfolio/praram9';
import PlScasset from './components/portfolio/scasset';
import PlDribbble from './components/portfolio/dribbble';
import PlVerME from './components/portfolio/verme';
import PlKafaak from './components/portfolio/kafaak';
import PlMandarin from './components/portfolio/mandarin';

const Routes = (props) => (
  <Router {...props}>
    <Route path="/" component={App} />
    <Route path="/about" component={About} />
    <Route path='/design-team-random' component={DesignTeamRandom} />
    <Route path='/landing-portfolio' component={LandingPortfolio} />
    <Route path='/portfolio' component={Portfolio} />
    <Route path='/private-portfolio' component={PrivatePortfolio} />
    <Route path='/private-portfolio/byd' component={PvBYD} />
    <Route path='/private-portfolio/taladthai' component={PvTaladThai} />
    <Route path='/private-portfolio/amway-tw' component={PvAmwayTW} />
    <Route path='/private-portfolio/bigc' component={PvBigC} />
    <Route path='/private-portfolio/vb-app' component={PvVbApp} />
    <Route path='/private-portfolio/va-platform' component={PvVaPlatform} />
    <Route path='/private-portfolio/sansiri' component={PvSansiri} />
    <Route path='/private-portfolio/praram9' component={PvPraram9} />
    <Route path='/private-portfolio/scasset' component={PvScasset} />
    <Route path='/private-portfolio/dribbble' component={PvDribbble} />
    <Route path='/private-portfolio/verme' component={PvVerME} />
    <Route path='/private-portfolio/kafaak' component={PvKafaak} />
    <Route path='/private-portfolio/mandarin' component={PvMandarin} />

    <Route path='/portfolio/byd' component={PlBYD} />
    <Route path='/portfolio/taladthai' component={PlTaladThai} />
    <Route path='/portfolio/amway-tw' component={PlAmwayTW} />
    <Route path='/portfolio/bigc' component={PlBigC} />
    <Route path='/portfolio/vb-app' component={PlVbApp} />
    <Route path='/portfolio/sansiri' component={PlSansiri} />
    <Route path='/portfolio/praram9' component={PlPraram9} />
    <Route path='/portfolio/scasset' component={PlScasset} />
    <Route path='/portfolio/dribbble' component={PlDribbble} />
    <Route path='/portfolio/verme' component={PlVerME} />
    <Route path='/portfolio/kafaak' component={PlKafaak} />
    <Route path='/portfolio/mandarin' component={PlMandarin} />
    <Route path='/qr' component={() => { 
     window.location.href = 'https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2FPPQR.JPG?alt=media&token=b2a85fc7-8d46-415f-891f-3fb6f5d4b707'; 
     return null;
}}/>
  </Router>
);

export default Routes;