import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { render } from 'react-dom';
import '../index.scss';
import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.png';
import image4 from './images/4.png';
import image5 from './images/5.png';
import image6 from './images/6.png';
import image7 from './images/7.png';
import image8 from './images/8.png';
import image9 from './images/9.png';
import image10 from './images/10.png';
import image11 from './images/11.png';
import image12 from './images/12.png';
import image13 from './images/13.png';
import image14 from './images/14.png';
import image15 from './images/15.png';
import image16 from './images/16.png';

const image = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16];

class DesignTeamRandom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      random: null,
    }
  }

  min = 1;
  max = 16;

  handleClick = () => {
    const randomNumber = Math.floor(Math.random() * (this.max - this.min));
    this.setState({
      currentImageIndex: randomNumber
    });
  };

  render() {
    return (
      <div class="container about-body">
        <div class="row col-4 all-center align-center">
        <h2 class="text-primary mb-4">Who's next?</h2>
        <button onClick={this.handleClick} class="btn-outline-primary mb-3">Random!</button>
        <img src={image[this.state.currentImageIndex]}/>
        </div>
      </div>
    );
  }
}

export default DesignTeamRandom;