import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PlBigC extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">Big C</h2>
            <h5 class="mt-3">Big C Thailand</h5>
          </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FBigC%2FBig%20C%20-%201.png?alt=media&token=4880d8ae-82ac-4795-aa37-389df5a47d41" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              A concept for the Big C application to help the user easier to<br/>
              shop for products from the largest big box store in Thailand.
              </body>
            </div>
        </div>
        <div class="row align-center mt-5">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FBigC%2FBig%20C%20-%202.png?alt=media&token=790ac640-be8b-4244-8c6b-1f269a79e87d" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              Refreshing application UX and UI to look fresh and bolder promotions.<br/>
              The user also can filter and search for the product they want.
              </body>
            </div>
        </div>
        <div class="row align-center mt-5">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FBigC%2FBig%20C%20-%203.png?alt=media&token=0f82cc1b-9447-44bb-a300-1ba1c84b3691" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              New categoring for easier search products.<br/>
              Delivering at the door or pick-up at the store is also available for the user. 
              </body>
            </div>
        </div>
        <a href="/portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PlBigC;