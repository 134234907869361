import React, { Component } from 'react';
import logo from './logo.svg';
import './index.scss';
import Toasted from 'toastedjs';

// function App() {
//   return null;
// }
function toastedHome() {
  var toastedHome = new Toasted({
    position: 'top-center',
    theme: 'bubble', duration: 3000
  })
  toastedHome.show("yea, i'm here!")
}

function toastedPortfolio() {
  var toastedPortfolio = new Toasted({
    position: 'top-center',
    theme: 'bubble', duration: 3000
  })
  toastedPortfolio.show("coming soon.")
}

class App extends Component {
  render() {
    return (
      <div class="container index-body home-container">
        <div class="col-12 all-center align-center">
          <h1 class="font-face-circular-book text-primary">erthp</h1>
          <h4 class="font-face-circular-book color-secondary">designer | web developer | milk tea lover</h4>
          {/* <a class="index-menu" onClick={toastedHome}><h2 class="font-italic align-center color-primary">home</h2></a> */}
          <a href="/about" class="index-menu"><h2 class="font-face-circular-bold align-center color-primary mt-5">about</h2></a>
          <a href="/portfolio" class="index-menu"><h2 class="font-face-circular-bold align-center color-primary mt-5">portfolio</h2></a>
          <a href="https://medium.com/@erthp" class="index-menu"><h2 class="font-face-circular-bold align-center color-primary mt-5">blog</h2></a>
          {/* <a href="https://erthp.github.io"><button type="button" class="btn btn-outline-primary mt-2">about me</button></a> */}
        </div>
        <div class="col-12 index-footer">
          <p class="align-center mt-3 mb-1 color-secondary font-face-circular-bold">erthp</p>
          <div id="IndexFooter" class="align-center">
            <a href="https://www.facebook.com/erthp/"><img class="icon-24 icon-social"
              src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-facebook.svg?alt=media&token=6ef5c6cf-dc25-4877-beb1-1873a02358d7" /></a>
            <a href="https://www.instagram.com/erthp/"><img class="icon-24 icon-social"
              src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-instagram.svg?alt=media&token=1c5135cc-3b78-46ce-b375-b261641349ee" /></a>
            <a href="https://www.github.com/erthp/"><img class="icon-24 icon-social"
              src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-github.svg?alt=media&token=a919ba00-0018-4f88-882c-78efefbcc6b5" /></a>
            <a href="https://dribbble.com/erthp/"><img class="icon-24 icon-social"
              src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-dribbble.svg?alt=media&token=9e602604-8565-476d-b161-76782ed5999b" /></a>
          </div>
          <a href="mailto:iam@erthp.com"><h6 class="align-center mt-3 mb-1 color-secondary font-face-circular-bold">iam@erthp.com</h6></a>
        </div>
      </div>
    )
  }
}

export default App;