import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PlScasset extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">SC Asset</h2>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Research from former website on Hotjar and
Google analytics for tracking user’s behavior.
Define pain point and solution on each page.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FScasset%2Fscasset-1.png?alt=media&token=077532b9-ed1f-4d39-9611-7b40cd60f84e" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Design desktop and mobile using for web development. 
Launched on <a href="https://www.scasset.com/" target="_blank" rel="noopener noreferrer">https://www.scasset.com/</a></p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FScasset%2Fscasset-2.png?alt=media&token=9e064a53-2785-4159-9072-00b9da8c1dda" class="portfolio-image"/>
            </div>
        </div>
        <a href="/portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PlScasset;