import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PvSansiri extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/private-portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">Sansiri The Joy of Huahin</h2>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Persona: Define people in target groups and
motivation of people who want to
buyreal estates.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FSansiri%2Fsansiri-1.png?alt=media&token=a91e48e9-086e-4d71-87c4-dcd87dae3f4f" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Moodboard: Summer paradise, Tropical, Beach, and 
Summer foods</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FSansiri%2Fsansiri-2.png?alt=media&token=99eb4814-306c-4c68-93d9-e203d1de1fa5" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Wireframing: Design website’s layout depends onsitemap and priority.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FSansiri%2Fsansiri-3.png?alt=media&token=ce90d858-1c38-4ab1-b58a-b091066d8bfa" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Design mockups for desktop and mobile. </p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FSansiri%2Fsansiri-4.png?alt=media&token=9741f42e-1829-4608-842a-e83b32bb46ae" class="portfolio-image"/>
            </div>
        </div>
        <a href="/private-portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PvSansiri;