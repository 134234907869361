import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import '../index.scss';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
import anime from 'animejs/lib/anime.es.js';

class About extends Component {
  render() {
    return (
      <div class="container about-body">
        {/* <nav class="navbar">
          <span class="navbar-icon"><a href="/"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-home.svg?alt=media&token=9004de4a-55da-4fb2-bb58-f601f3341dbb" /></a></span>
          <span class="navbar-icon"><a href="/"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-home.svg?alt=media&token=9004de4a-55da-4fb2-bb58-f601f3341dbb" /></a></span>
          <span class="navbar-icon"><a href="/"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-home.svg?alt=media&token=9004de4a-55da-4fb2-bb58-f601f3341dbb" /></a></span>
          <span class="navbar-icon"><a href="/"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-home.svg?alt=media&token=9004de4a-55da-4fb2-bb58-f601f3341dbb" /></a></span>
          <span class="navbar-icon"><a href="/"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-home.svg?alt=media&token=9004de4a-55da-4fb2-bb58-f601f3341dbb" /></a></span>
          <span class="navbar-icon"><a href="/"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-home.svg?alt=media&token=9004de4a-55da-4fb2-bb58-f601f3341dbb" /></a></span>
        </nav> */}
        <div class="row">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <a class="" href="/"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h1 class="mt-5 font-face-circular-bold">Panot</h1>
            <h1 class="font-face-circular-bold">Riankamol</h1>
            <h3 class="mb-5 font-face-circular-book">🌏 Erth</h3>
          </div>
          <div class="col-xl-6 col-md-6 col-12 col-right">
            <div class="col-12 mb-5">
              <h2 class="text-primary font-face-circular-book">Who am i?</h2>
              <p class="color-primary font-face-circular-book">I'm a UX/UI Designer, currently working at OOZOU.</p>
            </div>
            <div class="col-12">
              <h2 class="text-primary font-face-circular-book">Experiences</h2>
              <div class="row mt-3">
                <div class="col-4">
                  <p class="color-primary font-face-circular-bold">Jun 2021–</p>
                </div>
                <div class="col-8">
                  <h5 class="color-primary font-face-circular-bold">Product Designer</h5>
                  <p class="color-primary mb-1 font-face-circular-book">OOZOU</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Research and design for e-commerce application and website in Thailand and Taiwan.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Research and design for marketplace application.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Organize and create new design system and design library for projects.</p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4">
                  <p class="color-primary font-face-circular-bold">Jul 2020–May 2020</p>
                </div>
                <div class="col-8">
                  <h5 class="color-primary font-face-circular-bold">UX/UI Designer</h5>
                  <p class="color-primary mb-1 font-face-circular-book">The Existing Company</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Research and design for fraud history checking application.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Research and design for HR management platform.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Organize and create new design system and design library for projects.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Design for back office application for mobile career company.</p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4">
                  <p class="color-primary font-face-circular-bold">Jun 2019–Jul 2020</p>
                </div>
                <div class="col-8">
                  <h5 class="color-primary font-face-circular-bold">UX/UI Designer</h5>
                  <p class="color-primary mb-1 font-face-circular-book">ViaBus</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Research and design for public transport tracking application.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Research and design for public transport management platform.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Research new features from customer's needs.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Design new features and library for using in design process.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Create style guidelines for mobile application and platform.</p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4">
                  <p class="color-primary font-face-circular-bold">Jan 2019–May 2019</p>
                </div>
                <div class="col-8">
                  <h5 class="color-primary font-face-circular-bold">UX/UI Designer</h5>
                  <p class="color-primary mb-1 font-face-circular-book">G-Able</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Research for website’s users in pain point, user persona, user journey for hospital,real estate, and company website project.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Design website’s prototype from UX research and brand’s CI.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Design mockup for hospital, real estate, and company website project.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Design style guide for mockup design.</p>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4">
                  <p class="color-primary font-face-circular-bold">Jun 2018–Jul 2018</p>
                </div>
                <div class="col-8">
                  <h5 class="color-primary font-face-circular-bold">Intern UX/UI Designer</h5>
                  <p class="color-primary mb-1 font-face-circular-book">G-Able</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Research for website’s users in pain point, user persona, user journey for hospital,real estate, and restaurant website project.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Analyze website by using tools such as Google Analytics, Hotjar to view website heatmap, clicking, scrolling, and all of user details.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Design website’s prototype from UX research and brand’s moodboard.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Design icon for using in websites.</p>
                  <p class="color-primary subtext1 mb-1 font-face-circular-book">• Design style guide for using in websites.</p>
                </div>
              </div>
              <div class="col-12 mt-5">
                <h2 class="text-primary font-face-circular-book">Education</h2>
                <div class="row mt-3">
                  <div class="col-4">
                    <p class="color-primary font-face-circular-bold">2015–2018</p>
                  </div>
                  <div class="col-8">
                    <h5 class="color-primary font-face-circular-bold">B.Sc. Information Technology</h5>
                    <p class="color-primary mb-1 font-face-circular-book">School of Information Technology,<br />
                            King Mongkut’s University of Technology Thonburi
                </p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-4">
                    <p class="color-primary font-face-circular-bold">2012–2015</p>
                  </div>
                  <div class="col-8">
                    <h5 class="color-primary font-face-circular-bold">Science-Mathematics Program</h5>
                    <p class="color-primary mb-1 font-face-circular-book">Taweethapisek School</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <h2 class="text-primary">Experiences</h2>
          </div>
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <div class="row mt-3">
              <div class="col-4">
                <p class="color-primary font-face-circular-bold">Oct 2020</p>
              </div>
              <div class="col-8">
                <h5 class="color-primary font-face-circular-bold">LINE HACK 2020 Finalist</h5>
                <p class="color-primary mb-1">Product Designer @ Kafaak</p>
                <p class="color-primary subtext1 mb-1">• Research users in pain point, user persona, and user journey.</p>
                <p class="color-primary subtext1 mb-1">• Design logo, icons, color, and application moodboard.</p>
                <p class="color-primary subtext1 mb-1">• Design wireframe for an application.</p>
                <p class="color-primary subtext1 mb-1">• Design all prototype for an application.</p>
                <p class="color-primary subtext1 mb-1">• Design keynote for final round presentation.</p>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 col-12 col-right">
            <Splide options={{
              type: 'loop',
              height: '16rem',
              cover: true,
              autoplay: true,
              lazyLoad: 'nearby',
              arrowPath: '0'
            }}>
              <SplideSlide>
                <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fline-hack-1.jpg?alt=media&token=dea8e0e3-4579-4180-8e10-8027ef45f470" alt="Image 1" />
              </SplideSlide>
              <SplideSlide>
                <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fline-hack-2.jpg?alt=media&token=1c0d49f7-5e8e-4ffc-82a0-18c409bbe093" alt="Image 2" />
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* <div class="row">
        <div class="col-6 col-md-6 col-xs-12 col-left">
            <div class="row mt-3">
              <div class="col-4">
                <p class="color-primary">Oct 2020</p>
              </div>
              <div class="col-8">
                <h5 class="color-primary">Lorem</h5>
                <p class="color-primary mb-1">Ipsum</p>
                <p class="color-primary subtext1 mb-1">• Research for website’s users in pain point, user persona, user journey for hospital,real estate, and restaurant website project.</p>
                <p class="color-primary subtext1 mb-1">• Analyze website by using tools such as Google Analytics, Hotjar to view website heatmap, clicking, scrolling, and all of user details.</p>
                <p class="color-primary subtext1 mb-1">• Design website’s prototype from UX research and brand’s moodboard.</p>
                <p class="color-primary subtext1 mb-1">• Design icon for using in websites.</p>
                <p class="color-primary subtext1 mb-1">• Design style guide for using in websites.</p>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 col-xs-12 col-right">
            <Splide options={ {
    type : 'loop',
    height  : '16rem',
    cover : true,
    autoplay: true,
    lazyLoad: 'nearby',
    arrowPath: '0'
  } }>
              <SplideSlide>
                <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fline-hack-1.jpg?alt=media&token=dea8e0e3-4579-4180-8e10-8027ef45f470" alt="Image 1" />
              </SplideSlide>
              <SplideSlide>
                <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fline-hack-2.jpg?alt=media&token=1c0d49f7-5e8e-4ffc-82a0-18c409bbe093" alt="Image 2" />
              </SplideSlide>
            </Splide>
          </div>
        </div> */}
        <div class="row mt-5">
          <div class="col-12 col-md-12 col-xs-12 col-left">
            <h2 class="text-primary">Personal</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-md-3 col-6 col-left mt-3">
            <p class="color-secondary subtext1 mb-1 font-face-circular-book">Birthdate</p>
            <h5 class="color-primary font-face-circular-bold">10 March 1997</h5>
          </div>
          <div class="col-xl-3 col-md-3 col-6 col-left mt-3">
            <p class="color-secondary subtext1 mb-1 font-face-circular-book">Nationality</p>
            <h5 class="color-primary font-face-circular-bold">Thai</h5>
          </div>
          <div class="col-xl-3 col-md-3 col-6 mt-3">
            <p class="color-secondary subtext1 mb-1 font-face-circular-book">Languages</p>
            <h5 class="color-primary font-face-circular-bold">Thai, English</h5>
          </div>
          <div class="col-xl-3 col-md-3 col-6 mt-3">
            <p class="color-secondary subtext1 mb-1 font-face-circular-book">Hometown</p>
            <h5 class="color-primary font-face-circular-bold">Bangkok, Thailand</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-md-3 col-6 col-left mt-3">
            <p class="color-secondary subtext1 mb-1 font-face-circular-book">Email</p>
            <a href="mailto:iam@erthp.com"><h5 class="color-primary font-face-circular-bold">iam@erthp.com</h5></a>
          </div>
          <div class="col-xl-3 col-md-3 col-6 col-left mt-3">
            <p class="color-secondary subtext1 mb-1 font-face-circular-book">Mobile</p>
            <a href="tel+66800516844"><h5 class="color-primary font-face-circular-bold">(+66) 62 917 8688</h5></a>
          </div>
          <div class="col-xl-3 col-md-3 col-6 mt-3">
            <p class="color-secondary subtext1 mb-1 font-face-circular-book">Facebook</p>
            <a href="https://www.facebook.com/erthp"><h5 class="color-primary font-face-circular-bold">/erthp</h5></a>
          </div>
          <div class="col-xl-3 col-md-3 col-6 mt-3">
            <p class="color-secondary subtext1 mb-1 font-face-circular-book">Instagram</p>
            <a href="https://www.instagram.com/erthp"><h5 class="color-primary font-face-circular-bold">/erthp</h5></a>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 col-md-12 col-xs-12 col-left">
            <h2 class="text-primary">Skills</h2>
          </div>
          <div class="row mt-3">
            <div class="col-xl-3 col-md-3 col-12 col-left">
              <h5 class="text-primary font-face-circular-bold">Wireframing Tools</h5>
            </div>
            <div class="col-xl-9 col-md-9 col-12 col-left">
              <span class="skill-badge skill-white"><img src="https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg" class="skill-logo" /> Figma</span>
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/sketch-2.svg" class="skill-logo" /> Sketch</span>
              <span class="skill-badge skill-white"><img src="https://upload.wikimedia.org/wikipedia/commons/c/c2/Adobe_XD_CC_icon.svg" class="skill-logo" /> Adobe XD</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-3 col-md-3 col-12 col-left">
              <h5 class="text-primary font-face-circular-bold">Delivery Tools</h5>
            </div>
            <div class="col-xl-9 col-md-9 col-12 col-left">
              <span class="skill-badge skill-white"><img src="https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg" class="skill-logo" /> Figma</span>
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/zeplin.svg" class="skill-logo" /> Zeplin</span>
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/invision.svg" class="skill-logo" /> InVision</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-3 col-md-3 col-12 col-left">
              <h5 class="text-primary font-face-circular-bold">Design Tools</h5>
            </div>
            <div class="col-xl-9 col-md-9 col-12 col-left">
              <span class="skill-badge skill-white"><img src="https://upload.wikimedia.org/wikipedia/commons/a/af/Adobe_Photoshop_CC_icon.svg" class="skill-logo" /> Adobe Photoshop</span>
              <span class="skill-badge skill-white"><img src="https://upload.wikimedia.org/wikipedia/commons/f/fb/Adobe_Illustrator_CC_icon.svg" class="skill-logo" /> Adobe Illustrator</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-3 col-md-3 col-12 col-left">
              <h5 class="text-primary font-face-circular-bold">Animation Tools</h5>
            </div>
            <div class="col-xl-9 col-md-9 col-12 col-left">
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/principle-app-2.svg" class="skill-logo" /> Principle</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-3 col-md-3 col-12 col-left">
              <h5 class="text-primary font-face-circular-bold">UX Tools</h5>
            </div>
            <div class="col-xl-9 col-md-9 col-12 col-left">
              <span class="skill-badge skill-white"><img src="https://ph-files.imgix.net/1fc4ade5-c5f0-4bf9-8994-c5d9a576e4ad" class="skill-logo" /> Whimsical</span>
              <span class="skill-badge skill-white"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Flogo-lucidchart.svg?alt=media&token=1008f34f-a115-45ea-8c53-6e19c9add737" class="skill-logo" /> Lucidchart</span>
              <span class="skill-badge skill-white"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Flogo-overflow.svg?alt=media&token=1a9797b1-5576-4ebd-b07c-b2f63d175b7e" class="skill-logo" /> Overflow</span>
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/hotjar-2.svg" class="skill-logo" /> Hotjar</span>
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/google-analytics-4.svg" class="skill-logo" /> Google Analytics</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-xl-3 col-md-3 col-12 col-left">
              <h5 class="text-primary font-face-circular-bold">Coding</h5>
            </div>
            <div class="col-xl-9 col-md-9 col-12 col-left">
              <span class="skill-badge skill-white"><img src="https://upload.wikimedia.org/wikipedia/commons/6/61/HTML5_logo_and_wordmark.svg" class="skill-logo" /> HTML5</span>
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/css-3.svg" class="skill-logo" /> CSS3</span>
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/logo-javascript.svg" class="skill-logo" /> JavaScript</span>
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/laravel-2.svg" class="skill-logo" /> Laravel</span>
            </div>
          </div>
          <div class="row mt-3 mb-5">
            <div class="col-xl-3 col-md-3 col-12 col-left">
              <h5 class="text-primary font-face-circular-bold">Software Development</h5>
            </div>
            <div class="col-xl-9 col-md-9 col-12 col-left">
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/aws-ec2.svg" class="skill-logo" /> AWS EC2</span>
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/aws-rds.svg" class="skill-logo" /> AWS RDS</span>
              <span class="skill-badge skill-white"><img src="https://cdn.worldvectorlogo.com/logos/firebase-1.svg" class="skill-logo" /> Firebase</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default About;