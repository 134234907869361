import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import '../index.scss';

class Portfolio extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h1 class="mt-3 font-face-circular-book color-primary">Portfolio</h1>
          </div>
        </div>
        <div class="row portfolio-row mt-3">
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/byd"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FBYD%2FBYD%20-%201.png?alt=media&token=bab2647e-b9b6-4a5f-9b52-855fd1b2db47" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">BYD</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UX</span><span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">Mobile App</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/taladthai"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FTaladThai%2FTaladThai%20-%201.png?alt=media&token=cd02cdc1-482c-4b9c-bef7-0c2f252e47db" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">Talad Thai</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UX</span><span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">Mobile App</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/amway-tw"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FAmwayTW%2FAmway%20TW%20-%201.png?alt=media&token=a798b300-4bc0-4b65-871e-5eb0a4b242fe" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">Amway Taiwan</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">Desktop Web</span><span class="portfolio-badge skill-white text-secondary">Mobile Web</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/bigc"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FBigC%2FBig%20C%20-%201.png?alt=media&token=4880d8ae-82ac-4795-aa37-389df5a47d41" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">Big C</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">Mobile App</span><span class="portfolio-badge skill-white text-secondary">Concept Design</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/vb-app"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2Fcover-vb-app.png?alt=media&token=2c5fa106-4af9-4a47-b649-b961c636adde" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">ViaBus App</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">Mobile App</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/sansiri"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2Fcover-sansiri.png?alt=media&token=8cd2330f-fbfc-4505-99c5-1f7344d82857" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">Sansiri The Joy of Huahin</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UX</span><span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">Desktop Web</span><span class="portfolio-badge skill-white text-secondary">Mobile Web</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/dribbble"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2Fcover-dribbble.png?alt=media&token=4202fee1-cd7c-46c7-9009-330ecf347dc4" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">Concept Design</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UI</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/verme"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2Fcover-verme.png?alt=media&token=41a2d8cd-01eb-47ef-be7a-4a8e7b93c3ab" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">VerME</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">Desktop Web</span><span class="portfolio-badge skill-white text-secondary">Tablet Web</span><span class="portfolio-badge skill-white text-secondary">Mobile Web</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/kafaak"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2Fcover-kafaak.png?alt=media&token=dafad216-400c-485b-8507-1b2aee81207b" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">Kafaak</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UX</span><span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">LINE LIFF</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/praram9"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2Fcover-praram9.png?alt=media&token=9333c358-029c-4c9a-aecc-fc9b9e031c0b" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">Praram 9 Hospital</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UX</span><span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">Desktop Web</span><span class="portfolio-badge skill-white text-secondary">Mobile Web</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/scasset"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2Fcover-scasset.png?alt=media&token=d4d338a7-8492-462f-8014-ff834bd8fa8f" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">SC Asset</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UX</span><span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">Desktop Web</span><span class="portfolio-badge skill-white text-secondary">Mobile Web</span>
          </div>
          <div class="col-xl-4 col-md-6 col-12 portfolio-col mb-3">
            <a href="/portfolio/mandarin"><img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2Fcover-mandarin.png?alt=media&token=0a2e0f12-5734-4282-970f-38bf5d7a7e89" class="portfolio-cover" />
              <h5 class="text-primary mt-3 mb-1 font-face-circular-book">Mandarin Hotel</h5>
            </a>
            <span class="portfolio-badge skill-white text-secondary">UI</span><span class="portfolio-badge skill-white text-secondary">Desktop Web</span>
          </div>
        </div>
      </div> 
      )
    }
}
  
  export default Portfolio;