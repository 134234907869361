import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PvBYD extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/private-portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">BYD</h2>
            <h5 class="mt-3">Rêver Automotive</h5>
          </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FBYD%2FBYD%20-%201.png?alt=media&token=bab2647e-b9b6-4a5f-9b52-855fd1b2db47" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              The super-app for one of the top automotive manufacturers from China.<br/>
              A Rêver automotive super-app for BYD Thailand provided the necessary features for BYD drivers.
              </body>
            </div>
        </div>
        <div class="row align-center mt-5">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FBYD%2FBYD%20-%203.png?alt=media&token=9c428a00-e127-4141-a20d-0773abeed769" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              Service booking, warranty claiming, buying accessories,<br/>
              or getting test drives were available on just one application.<br/>
              The application UX also should fit with the new generation of electric car drivers.
              </body>
            </div>
        </div>
        <div class="row align-center mt-5">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FBYD%2FBYD%20-%202.png?alt=media&token=b5481c3b-b216-4196-85d1-031c85f46673" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              One of the most important features is the charging stations' findings.<br/>
              The UX should be smooth when the user rushes to the app and find locations.<br/>
              The app also has gamification features like carbon offset to<br/>
              let the user see how they can reduce carbon for the world.
              </body>
            </div>
        </div>
        <a href="/portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PvBYD;