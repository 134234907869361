import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PvDribbble extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/private-portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">Concept Design</h2>
          </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FDribbble%2FBitter%20Sweet.png?alt=media&token=e98aa76d-f736-4e86-a0b9-66445ba51c34" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FDribbble%2FMarketplace%20-%20Shot%2019.png?alt=media&token=a5c14579-ce6e-41b4-9dd0-41155a8cf0ef" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://cdn.dribbble.com/users/4577224/screenshots/14967721/media/136275c2e1e4c04fe9656ee62b2935cb.png" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://cdn.dribbble.com/users/6999928/screenshots/15086369/media/78495cfdda688616b917d954db11e409.png" class="portfolio-image-full"/>
            </div>
        </div>
        <a href="/private-portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PvDribbble;