import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PlVbApp extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">ViaBus App</h2>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">"Less is more"
Concept design for ViaBus application
focused on current location and real time buses.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FViaBus%2Fvba-1.png?alt=media&token=1789f5d1-267b-4482-8661-f9f045a4e50f" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">New Info window design for vehicles and bus stops
clearly showed informations on buses, such as bus number, license plate, status icons, ETA, and etc.
And informations on bus stops to support multilingual.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FViaBus%2Fvba-2.png?alt=media&token=b7e0a1b6-84dd-4687-8cdf-30ad98d07aee" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Design function that supports alert and announcements,
such as transportation news, official announcements, and extenuating circumstances.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FViaBus%2Fvba-3.png?alt=media&token=3ec792cc-0534-4552-aa12-7f6f47c3abe9" class="portfolio-image"/>
            </div>
        </div>
        <a href="/private-portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PlVbApp;