import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PvMandarin extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/private-portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">Mandarin Hotel</h2>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Website design for Mandarin Hotel, Bangkok.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FMandarin%2Fmandarin.png?alt=media&token=1616e5c7-5e41-480c-8d73-d3dedf401333" class="portfolio-image"/>
            </div>
        </div>
        <a href="/private-portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PvMandarin;