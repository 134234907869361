import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PlTaladThai extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">Talad Thai</h2>
            <h5 class="mt-3">Freshliving / Talad Thai</h5>
          </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FTaladThai%2FTaladThai%20-%201.png?alt=media&token=cd02cdc1-482c-4b9c-bef7-0c2f252e47db" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              The application for one of the largest fresh markets in Thailand.<br/>
              Talad Thai has over 1,000 sellers to sell fresh fruits and vegetables,<br/>
              fresh meats and seafood, and also dry products like rice and miscellaneous things.<br/>
              </body>
            </div>
        </div>
        <div class="row align-center mt-5">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FTaladThai%2FTaladThai%20-%204.png?alt=media&token=3dd86cfa-b6cc-4854-b8ce-ece852429f42" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              Everything was built from scratch.<br/>
              Since gathering requirements, researching market details,<br/>
              and the market users' behavior to make the convenient application for this wholesale market buyer.
              </body>
            </div>
        </div>
        <div class="row align-center mt-5">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FTaladThai%2FTaladThai%20-%202.png?alt=media&token=f930b318-df07-46d7-ba69-0df3b3facfba" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              The large marketplace with the wholesale price.<br/>
              The challenge of fresh products is unpredictable prices every day.<br/>
              The application could be dynamic, easy to manage, and also user-friendly.
              </body>
            </div>
        </div>
        <div class="row align-center mt-5">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FTaladThai%2FTaladThai%20-%203.png?alt=media&token=cd975245-144b-468e-b7da-f61c8e93f9ba" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              The application also supports all cases for dynamic variants of the products like<br/>
              weights, colors, and types. Also easily to checkout with PromptPay and credit cards via the app.
              </body>
            </div>
        </div>
        <a href="/portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PlTaladThai;