import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PvAmwayTW extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/private-portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">Amway Taiwan</h2>
            <h5 class="mt-3">Amway</h5>
          </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FAmwayTW%2FAmway%20TW%20-%201.png?alt=media&token=a798b300-4bc0-4b65-871e-5eb0a4b242fe" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              The e-commerce platform for one of the top-notch e-commerce chains in the world.<br/>
              Amway Taiwan is provided beauty, health, home care, and other products.<br/>
              The website has supported on both desktop and mobile, also available in English and Chinese.<br/>
              This is a challenge project to redesign for supporting the Taiwanese.
              </body>
            </div>
        </div>
        <div class="row align-center mt-5">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FAmwayTW%2FAmway%20TW%20-%202.png?alt=media&token=b71c4171-25da-44dd-899b-6737820ec6bf" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              With the colorful brand identity, the design needs to be clean and minimalistic.<br/>
              The platform also has back-end services to support the user to manage their profile or personal things.
              </body>
            </div>
        </div>
        <div class="row align-center mt-5">
            <div class="col-xl-12 col-md-12 col-12">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FAmwayTW%2FAmway%20TW%20-%203.png?alt=media&token=47d1959a-786c-4556-a689-d39cdac316b6" class="portfolio-image-full"/>
            </div>
        </div>
        <div class="row align-center mt-3">
            <div class="col-xl-12 col-md-12 col-12">
              <body>
              The full e-commerce platform with live selling features to makes it more attractive for the user.<br/>
              The account center and category management were easy to accessible with icons.
              </body>
            </div>
        </div>
        <a href="/portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PvAmwayTW;