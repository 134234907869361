import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import '../../index.scss';


class PvPraram9 extends Component {
    render() {
      return (     
        <div class="container about-body">
        <div class="row">
          <div class="col-xl-12 col-md-12 col-12 col-left">
            <a class="" href="/private-portfolio"><img class="icon-24" src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Assets%2Fic-back.svg?alt=media&token=eece959f-4051-4677-9e80-822d47328882" /></a>
            <h2 class="mt-3">Praram 9 Hospital</h2>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Research users who use private hospital and analyze words,
features, andwhat’s important for patients.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FPraram9%2Fpraram9-1.png?alt=media&token=8a99bc68-5770-4824-9497-ef80d0daca57" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Persona: Define people in target groups who using
hospital’s services online and offline.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FPraram9%2Fpraram9-2.png?alt=media&token=898905cc-75ed-4cc5-b90b-a9ef1432c0b5" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Make an user journey to define journey maps
            from user personas.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FPraram9%2Fpraram9-3.png?alt=media&token=45f0746b-a593-4d59-9dcb-719111d38a98" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Define style guide for using in desktop an
mobile design.</p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FPraram9%2Fpraram9-4.png?alt=media&token=a54ded6b-0004-4080-8c0a-36f1a8f77f15" class="portfolio-image"/>
            </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-xl-6 col-md-6 col-12 col-left">
            <p class="text-primary pt-5">Design desktop and mobile using for web development. 
Launched on <a href="https://www.praram9.com/" target="_blank" rel="noopener noreferrer">https://www.praram9.com/</a></p>
            </div>
            <div class="col-xl-6 col-md-6 col-12 col-right">
              <img src="https://firebasestorage.googleapis.com/v0/b/erthp-19c99.appspot.com/o/Images%2Fportfolio%2FPraram9%2Fpraram9-5.png?alt=media&token=c74b0bfa-64b9-4037-803c-6e587fd97313" class="portfolio-image"/>
            </div>
        </div>
        <a href="/private-portfolio"><p class="align-center mt-3 mb-5 color-primary font-semibold">back to portfolio</p></a>
        </div>
      )
    }
}
  
  export default PvPraram9;